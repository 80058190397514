import '../App.css'

import madeWithUnity from '../Images/MadeWithUnity.png'
import spine from '../Images/SpineLogo.png'

function MadeWithUnity()
{
    // HTML
    return(
        <div className='border-b-8 border-b-[#13161C] bg-gradient-to-r from-[#0000] to-[#1b2728] flex justify-center items-center py-20 md:py-40 px-12 pl-6 md:px-30'>
            <a href='https://unity.com'>
                <img src={madeWithUnity} alt='madeWithUnity' width='356' className='hover:contrast-50 mr-8 transition-transform duration-300 transform hover:-translate-y-2' />
            </a>
            
            <a href='https://esotericsoftware.com'>
                <img src={spine} alt='spine' width='256' className='hover:contrast-50 ml-8 transition-transform duration-300 transform hover:-translate-y-2'/>
            </a>
        </div>
    )
}

export default MadeWithUnity;