import '../App.css'

function Blog()
{
    return(
        <div className='flex flex-col items-center justify-start'>
            <h2>BLOG</h2>
            <h3 className='py-4'>To get news and updates, visit our <a href='https://thedreamlurkerblog.screencrowstudios.com' className='text-[#396063] hover:contrast-50'>blog</a>!</h3>
        </div>
    )
}

export default Blog;