import '../App.css'

import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import screenshot1 from '../Images/Screenshots/Screenshot1.jpg'
import screenshot2 from '../Images/Screenshots/Screenshot2.jpg'
import screenshot3 from '../Images/Screenshots/Screenshot3.jpg'
import screenshot4 from '../Images/Screenshots/Screenshot4.jpg'
import screenshot5 from '../Images/Screenshots/Screenshot5.jpg'

function Media()
{
    // HTML
    return (
        <div className='flex flex-col items-center justify-start pt-10'>
            <h2>MEDIA</h2>
            <LightGallery elementClassNames='flex flex-wrap justify-center gap-5'>
                <a href={screenshot1}> <img alt="Opening The Door" src={screenshot1} className='transition-transform duration-300 transform hover:-translate-y-2'/> </a>
                <a href={screenshot2}> <img alt="Running Through The Forest" src={screenshot2} className='transition-transform duration-300 transform hover:-translate-y-2'/> </a>
                <a href={screenshot3}> <img alt="Climbing Tutorial" src={screenshot3} className='transition-transform duration-300 transform hover:-translate-y-2'/> </a>
                <a href={screenshot4}> <img alt="He awakens.." src={screenshot4} className='transition-transform duration-300 transform hover:-translate-y-2'/> </a>
                <a href={screenshot5}> <img alt="The Moon" src={screenshot5} className='transition-transform duration-300 transform hover:-translate-y-2'/> </a>
            </LightGallery>
        </div>
    )
}

export default Media;