import '../App.css'

import steamLogo from '../Images/SteamLogo.png'
import kickstarterLogo from '../Images/KickstarterLogo.png'

function GetTheDreamlurker()
{
    return (
        <div className='flex flex-col items-center justify-start'>
            <h2> GET THE DREAMLURKER:</h2>
            <div className='grid grid-cols-2 gap-5 items-center justify-center'>
               <a href='https://store.steampowered.com/app/2496390/The_Dreamlurker/'> <img src={steamLogo} alt='steamLogo' width='256' className='hover:contrast-50 transition-transform duration-300 transform hover:-translate-y-2'/> </a> 
               <a href='https://www.kickstarter.com/projects/screencrowstudios/insanity-a-game-inspired-by-limbo'> <img src={kickstarterLogo} alt='kickstarterLogo' width='256' className='hover:contrast-50 transition-transform duration-300 transform hover:-translate-y-2'/> </a>
            </div>
        </div>
    );
};

export default GetTheDreamlurker;