import React from 'react';
import '../App.css';
import '../Animations.css'
import { isMobile } from 'react-device-detect';

import poster1 from '../Images/Poster1.png'
import theDreamlurkerLogo from '../Images/TheDreamlurkerLogo.png';

function Header() 
{
    if (isMobile) 
    {
        return (
            <header className='bg-center border-[#13161C] border-solid border-b-4 flex justify-center items-center px-10 py-40'>
                <img src={poster1} alt='poster1' className='blur-sm absolute h-screen object-cover z-[-999] absolute w-auto min-w-full min-h-full max-w-full' />
                <a href='https://thedreamlurker.screencrowstudios.com'>
                    <img className='Logo' src={theDreamlurkerLogo} alt='logo' width='1024' />
                </a>
            </header>
        );
    } 
    else 
    {
        return (
            <header className='bg-center flex justify-center items-center px-20 py-60'>
                <img src={poster1} alt='poster1' className='blur-sm absolute h-screen object-cover z-[-999] absolute w-auto min-w-full min-h-full max-w-full' />
                <a href='https://thedreamlurker.screencrowstudios.com'>
                    <img className='Logo transform transition-transform duration-300 ease-in-out hover:scale-90' src={theDreamlurkerLogo} alt='logo' width='1024' />
                </a>

                {/*<header className='bg-center flex justify-center items-center px-20 py-40'>
                <video autoPlay muted loop className='absolute h-screen object-cover z-[-999] absolute w-auto min-w-full min-h-full max-w-full'>
                    <source src="https://cdn.discordapp.com/attachments/1114361864609075280/1132732810147405985/Movie_008.mp4" type="video/mp4"/>
                </video>
                <a href='https://insanity.screencrowstudios.com'>
                    <img src={insanityLogo} alt='insanityLogo' width='1024' />
                </a>
            </header>*/}
            </header>
        );
    }
}

export default Header;
