import './App.css'

import Body from './Elements/Body';
import Footer from './Elements/Footer';
import Header from './Elements/Header';
import SoftwareInUse from './Elements/SoftwareInUse';

function App() 
{
    return (
        <div>
            <Header/>
            <Body/>
            
            <SoftwareInUse/>
            <Footer/>
        </div>
    );
}

export default App;

