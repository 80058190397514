import '../App.css'

import GetTheDreamlurker from './GetTheDreamlurker'
import Blog from './Blog'
import Media from './Media'
import Newsletter from './Newsletter'

import veinsBackground from '../Images/VeinsBackground.png'

function Body()
{
    // HTML
    return (
        <div className='bg-gradient-to-t from-[#13161C] to-[#1b2728] border-t-8 border-t-[#13161C] border-b-8 border-b-[#1b2728] border-solid'>
            <img src={veinsBackground} alt='veinsBackground' className='absolute blur sm'/>
            
            <div className='relative rounded-md object-fill text-white text-center py-10 px-2'>
                <div className='flex flex-wrap gap-10 sm:gap-40 justify-center content-center rounded-md'>
                    <GetTheDreamlurker/>

                    <Blog/>
                </div>

                <Media/>
                
            </div>
        </div>
        
    )
}

export default Body;