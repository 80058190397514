import '../App.css';

function Newsletter()
{
    // HTML
    return(
        <div id="mc_embed_shell">
            <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css"/>
            <div id="mc_embed_signup">
                <form action="https://screencrowstudios.us22.list-manage.com/subscribe/post?u=f52eae3ad166873b97a3b26d4&amp;id=8551c2a1a3&amp;f_id=00b3d2e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                <div id="mc_embed_signup_scroll">
                    <h2>Subscribe To The Newsletter</h2>
                    <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                    <div class="mc-field-group">
                        <label for="mce-EMAIL">Email Address <span class="asterisk">*
                        </span>
                        </label>
                        <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""/>
                    </div>
                    <div hidden="">
                        <input type="hidden" name="tags" value="14299"/>
                    </div>
                    <div id="mce-responses" class="clear foot">
                        <div class="response" id="mce-error-response" style={{'display': 'none'}}/>
                        <div class="response" id="mce-success-response" style={{'display': 'none'}}/>
                    </div>
                    <div aria-hidden="true" style={{ 'position': 'absolute', 'left': '-5000px' }}>
                        {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                        <input type="text" name="b_f52eae3ad166873b97a3b26d4_8551c2a1a3" tabindex="-1" value=""/>
                    </div>
                    <div class="optionalParent">
                        <div class="clear foot">
                            <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe"/>
                            <p style={{'margin': '0px auto'}}>
                                <a href="http://eepurl.com/iVK0u2" title="Mailchimp - email marketing made easy and fun">
                                    <span style={{'display': 'inline-block', 'background-color': 'transparent', 'border-radius': '4px'}}>
                                        <img class="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg" alt="Intuit Mailchimp" style={{'width': '220px', 'height': '40px', 'display': 'flex', 'padding': '2px 0px', 'justify-content': 'center', 'align-items': 'center'}}/>
                                    </span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                </form>
            </div>
            <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
            <script type="text/javascript">(function($) window.fnames = new Array(); window.ftypes = new Array(); fnames[0]='EMAIL'; ftypes[0]='email'; fnames[1]='FNAME'; ftypes[1]='text'; fnames[2]='LNAME'; ftypes[2]='text'; fnames[3]='ADDRESS'; ftypes[3]='address'; fnames[4]='PHONE'; ftypes[4]='phone'; fnames[5]='BIRTHDAY'; ftypes[5]='birthday'(jQuery));var $mcj = jQuery.noConflict(true);</script>
        </div>

    )

}

export default Newsletter;